import { AccreditationCreateRequest as AccreditationCreateRequestMapper, CompanyCreateRequest as CompanyCreateRequestMapper, CompanyUpdateRequest as CompanyUpdateRequestMapper, TenderCreateRequest as TenderCreateRequestMapper, ReplyRequest as ReplyRequestMapper } from "../models/mappers";
export const accept = {
    parameterPath: "accept",
    mapper: {
        defaultValue: "application/json, text/json",
        isConstant: true,
        serializedName: "Accept",
        type: {
            name: "String"
        }
    }
};
export const $host = {
    parameterPath: "$host",
    mapper: {
        serializedName: "$host",
        required: true,
        type: {
            name: "String"
        }
    },
    skipEncoding: true
};
export const companyIds = {
    parameterPath: ["options", "companyIds"],
    mapper: {
        serializedName: "companyIds",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Uuid"
                }
            }
        }
    },
    collectionFormat: "CSV"
};
export const accept1 = {
    parameterPath: "accept",
    mapper: {
        defaultValue: "application/octet-stream",
        isConstant: true,
        serializedName: "Accept",
        type: {
            name: "String"
        }
    }
};
export const documentId = {
    parameterPath: "documentId",
    mapper: {
        serializedName: "documentId",
        required: true,
        type: {
            name: "Uuid"
        }
    }
};
export const contentType = {
    parameterPath: ["options", "contentType"],
    mapper: {
        defaultValue: "multipart/form-data",
        isConstant: true,
        serializedName: "Content-Type",
        type: {
            name: "String"
        }
    }
};
export const registryId = {
    parameterPath: ["options", "registryId"],
    mapper: {
        serializedName: "registryId",
        type: {
            name: "Uuid"
        }
    }
};
export const notes = {
    parameterPath: ["options", "notes"],
    mapper: {
        serializedName: "notes",
        type: {
            name: "String"
        }
    }
};
export const taxSystm = {
    parameterPath: ["options", "taxSystm"],
    mapper: {
        serializedName: "taxSystm",
        type: {
            name: "String"
        }
    }
};
export const companyId = {
    parameterPath: ["options", "companyId"],
    mapper: {
        serializedName: "companyId",
        type: {
            name: "Uuid"
        }
    }
};
export const accreditationClaim = {
    parameterPath: ["options", "accreditationClaim"],
    mapper: {
        serializedName: "accreditationClaim",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const efptExcerpt = {
    parameterPath: ["options", "efptExcerpt"],
    mapper: {
        serializedName: "efptExcerpt",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const taxExcerpt = {
    parameterPath: ["options", "taxExcerpt"],
    mapper: {
        serializedName: "taxExcerpt",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const budgetExcerpt = {
    parameterPath: ["options", "budgetExcerpt"],
    mapper: {
        serializedName: "budgetExcerpt",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const consistencyReference = {
    parameterPath: ["options", "consistencyReference"],
    mapper: {
        serializedName: "consistencyReference",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const legalEntitiesExcerpt = {
    parameterPath: ["options", "legalEntitiesExcerpt"],
    mapper: {
        serializedName: "legalEntitiesExcerpt",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const individualsInformationReference = {
    parameterPath: ["options", "individualsInformationReference"],
    mapper: {
        serializedName: "individualsInformationReference",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const incomeStatement = {
    parameterPath: ["options", "incomeStatement"],
    mapper: {
        serializedName: "incomeStatement",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const cashFlowStatement = {
    parameterPath: ["options", "cashFlowStatement"],
    mapper: {
        serializedName: "cashFlowStatement",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const revenueExplain = {
    parameterPath: ["options", "revenueExplain"],
    mapper: {
        serializedName: "revenueExplain",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const cashFlowExplain = {
    parameterPath: ["options", "cashFlowExplain"],
    mapper: {
        serializedName: "cashFlowExplain",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const accountingReference = {
    parameterPath: ["options", "accountingReference"],
    mapper: {
        serializedName: "accountingReference",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const confirmationLetter = {
    parameterPath: ["options", "confirmationLetter"],
    mapper: {
        serializedName: "confirmationLetter",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const accept2 = {
    parameterPath: "accept",
    mapper: {
        defaultValue: "application/json, text/json",
        isConstant: true,
        serializedName: "Accept",
        type: {
            name: "String"
        }
    }
};
export const id = {
    parameterPath: "id",
    mapper: {
        serializedName: "id",
        required: true,
        type: {
            name: "Uuid"
        }
    }
};
export const contentType1 = {
    parameterPath: ["options", "contentType"],
    mapper: {
        defaultValue: "application/json-patch+json",
        isConstant: true,
        serializedName: "Content-Type",
        type: {
            name: "String"
        }
    }
};
export const body = {
    parameterPath: ["options", "body"],
    mapper: AccreditationCreateRequestMapper
};
export const accreditationId = {
    parameterPath: "accreditationId",
    mapper: {
        serializedName: "accreditationId",
        required: true,
        type: {
            name: "Uuid"
        }
    }
};
export const requestId = {
    parameterPath: "requestId",
    mapper: {
        serializedName: "requestId",
        required: true,
        type: {
            name: "Uuid"
        }
    }
};
export const companyId1 = {
    parameterPath: "companyId",
    mapper: {
        serializedName: "companyId",
        required: true,
        type: {
            name: "Uuid"
        }
    }
};
export const body1 = {
    parameterPath: ["options", "body"],
    mapper: CompanyCreateRequestMapper
};
export const body2 = {
    parameterPath: ["options", "body"],
    mapper: CompanyUpdateRequestMapper
};
export const registryId1 = {
    parameterPath: "registryId",
    mapper: {
        serializedName: "registryId",
        required: true,
        type: {
            name: "Uuid"
        }
    }
};
export const body3 = {
    parameterPath: ["options", "body"],
    mapper: TenderCreateRequestMapper
};
export const body4 = {
    parameterPath: ["options", "body"],
    mapper: ReplyRequestMapper
};
