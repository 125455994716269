import * as coreClient from "@azure/core-client";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
export class ApiClient extends coreClient.ServiceClient {
    /**
     * Initializes a new instance of the ApiClient class.
     * @param credentials Subscription credentials which uniquely identify client subscription.
     * @param $host server parameter
     * @param options The parameter options
     */
    constructor(credentials, $host, options) {
        var _a, _b;
        if (credentials === undefined) {
            throw new Error("'credentials' cannot be null");
        }
        if ($host === undefined) {
            throw new Error("'$host' cannot be null");
        }
        // Initializing default values for options
        if (!options) {
            options = {};
        }
        const defaults = {
            requestContentType: "application/json; charset=utf-8",
            credential: credentials
        };
        const packageDetails = `azsdk-js-apiClient/1.0.0-beta.1`;
        const userAgentPrefix = options.userAgentOptions && options.userAgentOptions.userAgentPrefix
            ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
            : `${packageDetails}`;
        const optionsWithDefaults = Object.assign(Object.assign(Object.assign({}, defaults), options), { userAgentOptions: {
                userAgentPrefix
            }, baseUri: (_b = (_a = options.endpoint) !== null && _a !== void 0 ? _a : options.baseUri) !== null && _b !== void 0 ? _b : "{$host}" });
        super(optionsWithDefaults);
        // Parameter assignments
        this.$host = $host;
    }
    /** @param options The options parameters. */
    getCompanyData(options) {
        return this.sendOperationRequest({ options }, getCompanyDataOperationSpec);
    }
    /** @param options The options parameters. */
    getAvailablelRegistries(options) {
        return this.sendOperationRequest({ options }, getAvailablelRegistriesOperationSpec);
    }
    /**
     * @param documentId
     * @param options The options parameters.
     */
    getAccreditationRequestDocument(documentId, options) {
        return this.sendOperationRequest({ documentId, options }, getAccreditationRequestDocumentOperationSpec);
    }
    /** @param options The options parameters. */
    createAccrediationRequest(options) {
        return this.sendOperationRequest({ options }, createAccrediationRequestOperationSpec);
    }
    /**
     * @param id
     * @param options The options parameters.
     */
    getAdminAccreditation(id, options) {
        return this.sendOperationRequest({ id, options }, getAdminAccreditationOperationSpec);
    }
    /** @param options The options parameters. */
    getAdminAccreditations(options) {
        return this.sendOperationRequest({ options }, getAdminAccreditationsOperationSpec);
    }
    /** @param options The options parameters. */
    createAccreditation(options) {
        return this.sendOperationRequest({ options }, createAccreditationOperationSpec);
    }
    /**
     * @param accreditationId
     * @param options The options parameters.
     */
    getAccreditationWithRequests(accreditationId, options) {
        return this.sendOperationRequest({ accreditationId, options }, getAccreditationWithRequestsOperationSpec);
    }
    /**
     * @param requestId
     * @param options The options parameters.
     */
    approveAccreditationRequest(requestId, options) {
        return this.sendOperationRequest({ requestId, options }, approveAccreditationRequestOperationSpec);
    }
    /**
     * @param requestId
     * @param options The options parameters.
     */
    rejectAccreditationRequest(requestId, options) {
        return this.sendOperationRequest({ requestId, options }, rejectAccreditationRequestOperationSpec);
    }
    /**
     * @param companyId
     * @param options The options parameters.
     */
    getCompany(companyId, options) {
        return this.sendOperationRequest({ companyId, options }, getCompanyOperationSpec);
    }
    /** @param options The options parameters. */
    getUserCompanies(options) {
        return this.sendOperationRequest({ options }, getUserCompaniesOperationSpec);
    }
    /** @param options The options parameters. */
    getChartererCompanies(options) {
        return this.sendOperationRequest({ options }, getChartererCompaniesOperationSpec);
    }
    /** @param options The options parameters. */
    createCompany(options) {
        return this.sendOperationRequest({ options }, createCompanyOperationSpec);
    }
    /** @param options The options parameters. */
    updateCompany(options) {
        return this.sendOperationRequest({ options }, updateCompanyOperationSpec);
    }
    /** @param options The options parameters. */
    getAllLabels(options) {
        return this.sendOperationRequest({ options }, getAllLabelsOperationSpec);
    }
    /**
     * @param registryId
     * @param options The options parameters.
     */
    getRegistry(registryId, options) {
        return this.sendOperationRequest({ registryId, options }, getRegistryOperationSpec);
    }
    /** @param options The options parameters. */
    getAllRegistries(options) {
        return this.sendOperationRequest({ options }, getAllRegistriesOperationSpec);
    }
    /**
     * @param requestId
     * @param options The options parameters.
     */
    getReplyDetailed(requestId, options) {
        return this.sendOperationRequest({ requestId, options }, getReplyDetailedOperationSpec);
    }
    /** @param options The options parameters. */
    getAllReplies(options) {
        return this.sendOperationRequest({ options }, getAllRepliesOperationSpec);
    }
    /**
     * @param requestId
     * @param options The options parameters.
     */
    getRequestDetailed(requestId, options) {
        return this.sendOperationRequest({ requestId, options }, getRequestDetailedOperationSpec);
    }
    /**
     * @param requestId
     * @param options The options parameters.
     */
    getRequestTourOperatorDetailed(requestId, options) {
        return this.sendOperationRequest({ requestId, options }, getRequestTourOperatorDetailedOperationSpec);
    }
    /** @param options The options parameters. */
    getAllRequests(options) {
        return this.sendOperationRequest({ options }, getAllRequestsOperationSpec);
    }
    /** @param options The options parameters. */
    createRequest(options) {
        return this.sendOperationRequest({ options }, createRequestOperationSpec);
    }
    /** @param options The options parameters. */
    createReply(options) {
        return this.sendOperationRequest({ options }, createReplyOperationSpec);
    }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);
const getCompanyDataOperationSpec = {
    path: "/api/Accreditations/company-data",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CompanyDataDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.CompanyDataDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.CompanyDataDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getAvailablelRegistriesOperationSpec = {
    path: "/api/Accreditations/get-available-registries",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.RegistryDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.RegistryDtoICollectionApiResponse
        }
    },
    queryParameters: [Parameters.companyIds],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getAccreditationRequestDocumentOperationSpec = {
    path: "/api/Accreditations/accreditation-request-document/{documentId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        },
        404: {}
    },
    urlParameters: [Parameters.$host, Parameters.documentId],
    headerParameters: [Parameters.accept1],
    serializer
};
const createAccrediationRequestOperationSpec = {
    path: "/api/Accreditations/request-accreditation",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    formDataParameters: [
        Parameters.registryId,
        Parameters.notes,
        Parameters.taxSystm,
        Parameters.companyId,
        Parameters.accreditationClaim,
        Parameters.efptExcerpt,
        Parameters.taxExcerpt,
        Parameters.budgetExcerpt,
        Parameters.consistencyReference,
        Parameters.legalEntitiesExcerpt,
        Parameters.individualsInformationReference,
        Parameters.incomeStatement,
        Parameters.cashFlowStatement,
        Parameters.revenueExplain,
        Parameters.cashFlowExplain,
        Parameters.accountingReference,
        Parameters.confirmationLetter
    ],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept2],
    serializer
};
const getAdminAccreditationOperationSpec = {
    path: "/api/AdminAccreditations/{id}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.AdminAccreditationDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.AdminAccreditationDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.AdminAccreditationDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.id],
    headerParameters: [Parameters.accept],
    serializer
};
const getAdminAccreditationsOperationSpec = {
    path: "/api/AdminAccreditations",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.AdminAccreditationDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.AdminAccreditationDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.AdminAccreditationDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const createAccreditationOperationSpec = {
    path: "/api/AdminAccreditations/create-accreditation",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.AdminAccreditationDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.AdminAccreditationDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.AdminAccreditationDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.AdminAccreditationDtoApiResponse
        }
    },
    requestBody: Parameters.body,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getAccreditationWithRequestsOperationSpec = {
    path: "/api/AdminAccreditations/get-accreditation-with-requests/{accreditationId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.AccreditationFullDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.accreditationId],
    headerParameters: [Parameters.accept],
    serializer
};
const approveAccreditationRequestOperationSpec = {
    path: "/api/AdminAccreditations/approve-accreditation-request/{requestId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.requestId],
    headerParameters: [Parameters.accept],
    serializer
};
const rejectAccreditationRequestOperationSpec = {
    path: "/api/AdminAccreditations/reject-accreditation-request/{requestId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.requestId],
    headerParameters: [Parameters.accept],
    serializer
};
const getCompanyOperationSpec = {
    path: "/api/Companies/{companyId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CompanyDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.CompanyDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.CompanyDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.companyId1],
    headerParameters: [Parameters.accept],
    serializer
};
const getUserCompaniesOperationSpec = {
    path: "/api/Companies/get-user-companies",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CompanyDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.CompanyDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.CompanyDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getChartererCompaniesOperationSpec = {
    path: "/api/Companies/get-charterer-companies",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CompanyDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.CompanyDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.CompanyDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const createCompanyOperationSpec = {
    path: "/api/Companies/create-company",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.CompanyDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.CompanyDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.CompanyDtoApiResponse
        }
    },
    requestBody: Parameters.body1,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const updateCompanyOperationSpec = {
    path: "/api/Companies/update-company",
    httpMethod: "PUT",
    responses: {
        200: {
            bodyMapper: Mappers.CompanyDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.CompanyDtoApiResponse
        }
    },
    requestBody: Parameters.body2,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getAllLabelsOperationSpec = {
    path: "/api/Labels/get-all-labels",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.LabelDtoIListApiResponse
        },
        400: {
            bodyMapper: Mappers.LabelDtoIListApiResponse
        },
        404: {
            bodyMapper: Mappers.LabelDtoIListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getRegistryOperationSpec = {
    path: "/api/Registries/{registryId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.RegistryDetailedDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.RegistryDetailedDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.RegistryDetailedDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.registryId1],
    headerParameters: [Parameters.accept],
    serializer
};
const getAllRegistriesOperationSpec = {
    path: "/api/Registries/get-all-registries",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.RegistryDtoIListApiResponse
        },
        400: {
            bodyMapper: Mappers.RegistryDtoIListApiResponse
        },
        404: {
            bodyMapper: Mappers.RegistryDtoIListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getReplyDetailedOperationSpec = {
    path: "/api/Replies/{requestId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ReplyDetailedDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ReplyDetailedDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ReplyDetailedDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.requestId],
    headerParameters: [Parameters.accept],
    serializer
};
const getAllRepliesOperationSpec = {
    path: "/api/Replies/get-all-replies",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ReplyDtoIListApiResponse
        },
        400: {
            bodyMapper: Mappers.ReplyDtoIListApiResponse
        },
        404: {
            bodyMapper: Mappers.ReplyDtoIListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getRequestDetailedOperationSpec = {
    path: "/api/Requests/{requestId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.RequestDetailedDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.RequestDetailedDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.RequestDetailedDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.requestId],
    headerParameters: [Parameters.accept],
    serializer
};
const getRequestTourOperatorDetailedOperationSpec = {
    path: "/api/Requests/tour-operator/{requestId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.RequestForTourOperatorDetailedDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.RequestForTourOperatorDetailedDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.RequestForTourOperatorDetailedDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.requestId],
    headerParameters: [Parameters.accept],
    serializer
};
const getAllRequestsOperationSpec = {
    path: "/api/Requests/get-all-requests",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.RequestDtoIListApiResponse
        },
        400: {
            bodyMapper: Mappers.RequestDtoIListApiResponse
        },
        404: {
            bodyMapper: Mappers.RequestDtoIListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const createRequestOperationSpec = {
    path: "/api/Requests/CreateRequests",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.RequestDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.RequestDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.RequestDtoApiResponse
        }
    },
    requestBody: Parameters.body3,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const createReplyOperationSpec = {
    path: "/api/Requests/create-reply",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ReplyDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.ReplyDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ReplyDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ReplyDtoApiResponse
        }
    },
    requestBody: Parameters.body4,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
