import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
const UserProfile = () => {
    const { auth, locale, navStyle } = useSelector(({ settings }) => settings);
    const dispatch = useDispatch();
    const userMenuOptions = (_jsxs("ul", Object.assign({ className: "gx-user-popover" }, { children: [_jsx("li", { children: _jsx(Link, Object.assign({ className: "gx-login-form-forgot", to: "/profile" }, { children: "\u041C\u043E\u0439 \u043F\u0440\u043E\u0444\u0438\u043B\u044C" })) }), _jsx("li", { children: _jsx(Link, Object.assign({ className: "gx-login-form-forgot", to: "/user-auth/logout" }, { children: "\u0412\u044B\u0445\u043E\u0434" })) })] })));
    return (_jsx("div", Object.assign({ className: "gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row" }, { children: _jsxs(Popover, Object.assign({ placement: "bottomRight", content: userMenuOptions, trigger: "click" }, { children: [_jsx(Avatar, { src: "https://via.placeholder.com/150", className: "gx-size-40 gx-pointer gx-mr-3", alt: "" }), _jsxs("span", Object.assign({ className: "gx-avatar-name" }, { children: [" ", auth.name, " ", _jsx("i", { className: "icon icon-chevron-down gx-fs-xxs gx-ml-2" })] }))] })) })));
};
export default UserProfile;
