export const CompanyDataDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "CompanyDataDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CompanyDataDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CompanyDataDto = {
    type: {
        name: "Composite",
        className: "CompanyDataDto",
        modelProperties: {
            conpanyId: {
                serializedName: "conpanyId",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const RegistryDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "RegistryDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RegistryDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const RegistryDto = {
    type: {
        name: "Composite",
        className: "RegistryDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isCompleted: {
                serializedName: "isCompleted",
                type: {
                    name: "Boolean"
                }
            },
            companyName: {
                serializedName: "companyName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const OkResponseApiResponse = {
    type: {
        name: "Composite",
        className: "OkResponseApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "OkResponse"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const OkResponse = {
    type: {
        name: "Composite",
        className: "OkResponse",
        modelProperties: {
            success: {
                serializedName: "success",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const AdminAccreditationDtoApiResponse = {
    type: {
        name: "Composite",
        className: "AdminAccreditationDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "AdminAccreditationDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const AdminAccreditationDto = {
    type: {
        name: "Composite",
        className: "AdminAccreditationDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            companyId: {
                serializedName: "companyId",
                type: {
                    name: "Uuid"
                }
            },
            company: {
                serializedName: "company",
                type: {
                    name: "Composite",
                    className: "CompanyTenderDbEntity"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            beginRequestDateTime: {
                serializedName: "beginRequestDateTime",
                type: {
                    name: "DateTime"
                }
            },
            endRequestDateTime: {
                serializedName: "endRequestDateTime",
                type: {
                    name: "DateTime"
                }
            },
            isRequestClosed: {
                serializedName: "isRequestClosed",
                type: {
                    name: "Boolean"
                }
            },
            registryRequests: {
                serializedName: "registryRequests",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "AccreditationResultDto"
                        }
                    }
                }
            }
        }
    }
};
export const CompanyTenderDbEntity = {
    type: {
        name: "Composite",
        className: "CompanyTenderDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            mttProviderId: {
                serializedName: "mttProviderId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            fullOfficialName: {
                serializedName: "fullOfficialName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            inn: {
                serializedName: "inn",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            officialAddress: {
                serializedName: "officialAddress",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            phone: {
                serializedName: "phone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isAllowedCreateTender: {
                serializedName: "isAllowedCreateTender",
                type: {
                    name: "Boolean"
                }
            },
            ogrn: {
                serializedName: "ogrn",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            kpp: {
                serializedName: "kpp",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            requests: {
                serializedName: "requests",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestTenderDbEntity"
                        }
                    }
                }
            },
            replies: {
                serializedName: "replies",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ReplyTenderDbEntity"
                        }
                    }
                }
            },
            registryRequests: {
                serializedName: "registryRequests",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RegistryRequestTenderDbEntity"
                        }
                    }
                }
            }
        }
    }
};
export const RequestTenderDbEntity = {
    type: {
        name: "Composite",
        className: "RequestTenderDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            creatorUserId: {
                serializedName: "creatorUserId",
                type: {
                    name: "Uuid"
                }
            },
            destination: {
                serializedName: "destination",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            capacity: {
                serializedName: "capacity",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            noteDetails: {
                serializedName: "noteDetails",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            companyId: {
                serializedName: "companyId",
                type: {
                    name: "Uuid"
                }
            },
            company: {
                serializedName: "company",
                type: {
                    name: "Composite",
                    className: "CompanyTenderDbEntity"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            requestDueDateTime: {
                serializedName: "requestDueDateTime",
                type: {
                    name: "DateTime"
                }
            },
            resultDueDateTime: {
                serializedName: "resultDueDateTime",
                type: {
                    name: "DateTime"
                }
            },
            isPrivate: {
                serializedName: "isPrivate",
                type: {
                    name: "Boolean"
                }
            },
            requestStatusId: {
                serializedName: "requestStatusId",
                type: {
                    name: "Number"
                }
            },
            requestStatus: {
                serializedName: "requestStatus",
                type: {
                    name: "Composite",
                    className: "RequestStatusTenderDbEntity"
                }
            },
            replies: {
                serializedName: "replies",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ReplyTenderDbEntity"
                        }
                    }
                }
            },
            requestLabels: {
                serializedName: "requestLabels",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestLabelTenderDbEntity"
                        }
                    }
                }
            },
            additionalRequestItems: {
                serializedName: "additionalRequestItems",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestAdditionalItemTenderDbEntity"
                        }
                    }
                }
            }
        }
    }
};
export const RequestStatusTenderDbEntity = {
    type: {
        name: "Composite",
        className: "RequestStatusTenderDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            requests: {
                serializedName: "requests",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestTenderDbEntity"
                        }
                    }
                }
            }
        }
    }
};
export const ReplyTenderDbEntity = {
    type: {
        name: "Composite",
        className: "ReplyTenderDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            requestId: {
                serializedName: "requestId",
                type: {
                    name: "Uuid"
                }
            },
            request: {
                serializedName: "request",
                type: {
                    name: "Composite",
                    className: "RequestTenderDbEntity"
                }
            },
            companyId: {
                serializedName: "companyId",
                type: {
                    name: "Uuid"
                }
            },
            company: {
                serializedName: "company",
                type: {
                    name: "Composite",
                    className: "CompanyTenderDbEntity"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            note: {
                serializedName: "note",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            replyStatusId: {
                serializedName: "replyStatusId",
                type: {
                    name: "Number"
                }
            },
            replyStatus: {
                serializedName: "replyStatus",
                type: {
                    name: "Composite",
                    className: "ReplyStatusTenderDbEntity"
                }
            }
        }
    }
};
export const ReplyStatusTenderDbEntity = {
    type: {
        name: "Composite",
        className: "ReplyStatusTenderDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            replies: {
                serializedName: "replies",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ReplyTenderDbEntity"
                        }
                    }
                }
            }
        }
    }
};
export const RequestLabelTenderDbEntity = {
    type: {
        name: "Composite",
        className: "RequestLabelTenderDbEntity",
        modelProperties: {
            requestId: {
                serializedName: "requestId",
                type: {
                    name: "Uuid"
                }
            },
            request: {
                serializedName: "request",
                type: {
                    name: "Composite",
                    className: "RequestTenderDbEntity"
                }
            },
            labelId: {
                serializedName: "labelId",
                type: {
                    name: "Uuid"
                }
            },
            label: {
                serializedName: "label",
                type: {
                    name: "Composite",
                    className: "LabelTenderDbEntity"
                }
            }
        }
    }
};
export const LabelTenderDbEntity = {
    type: {
        name: "Composite",
        className: "LabelTenderDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            colorHexCode: {
                serializedName: "colorHexCode",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            requests: {
                serializedName: "requests",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestTenderDbEntity"
                        }
                    }
                }
            },
            replies: {
                serializedName: "replies",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ReplyTenderDbEntity"
                        }
                    }
                }
            },
            requestLabels: {
                serializedName: "requestLabels",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestLabelTenderDbEntity"
                        }
                    }
                }
            }
        }
    }
};
export const RequestAdditionalItemTenderDbEntity = {
    type: {
        name: "Composite",
        className: "RequestAdditionalItemTenderDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            requestId: {
                serializedName: "requestId",
                type: {
                    name: "Uuid"
                }
            },
            requestTenderDbEntity: {
                serializedName: "requestTenderDbEntity",
                type: {
                    name: "Composite",
                    className: "RequestTenderDbEntity"
                }
            },
            title: {
                serializedName: "title",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            text: {
                serializedName: "text",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            index: {
                serializedName: "index",
                type: {
                    name: "Number"
                }
            },
            documents: {
                serializedName: "documents",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestDocumentTenderDbEntity"
                        }
                    }
                }
            }
        }
    }
};
export const RequestDocumentTenderDbEntity = {
    type: {
        name: "Composite",
        className: "RequestDocumentTenderDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            requestAdditionalItemTenderDbEntityId: {
                serializedName: "requestAdditionalItemTenderDbEntityId",
                type: {
                    name: "Uuid"
                }
            },
            requestAdditionalItemTenderDbEntity: {
                serializedName: "requestAdditionalItemTenderDbEntity",
                type: {
                    name: "Composite",
                    className: "RequestAdditionalItemTenderDbEntity"
                }
            },
            fileName: {
                serializedName: "fileName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fileId: {
                serializedName: "fileId",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const RegistryRequestTenderDbEntity = {
    type: {
        name: "Composite",
        className: "RegistryRequestTenderDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            notes: {
                serializedName: "notes",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            userID: {
                serializedName: "userID",
                type: {
                    name: "Uuid"
                }
            },
            registryId: {
                serializedName: "registryId",
                type: {
                    name: "Uuid"
                }
            },
            registry: {
                serializedName: "registry",
                type: {
                    name: "Composite",
                    className: "RegistryTenderDbEntity"
                }
            },
            sourceCompanyId: {
                serializedName: "sourceCompanyId",
                type: {
                    name: "Uuid"
                }
            },
            sourceCompany: {
                serializedName: "sourceCompany",
                type: {
                    name: "Composite",
                    className: "CompanyTenderDbEntity"
                }
            },
            taxSystem: {
                serializedName: "taxSystem",
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            registryRequestStatusId: {
                serializedName: "registryRequestStatusId",
                type: {
                    name: "Number"
                }
            },
            resultNotes: {
                serializedName: "resultNotes",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryRequestStatus: {
                serializedName: "registryRequestStatus",
                type: {
                    name: "Composite",
                    className: "RegistryRequestStatusTenderDbEntity"
                }
            },
            requestAccreditationDocuments: {
                serializedName: "requestAccreditationDocuments",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestAccreditationDocumentTenderDbEntity"
                        }
                    }
                }
            },
            statusLogs: {
                serializedName: "statusLogs",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RegistryRequestStatusLogTenderDbEntity"
                        }
                    }
                }
            }
        }
    }
};
export const RegistryTenderDbEntity = {
    type: {
        name: "Composite",
        className: "RegistryTenderDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                constraints: {
                    MaxLength: 128
                },
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            companyId: {
                serializedName: "companyId",
                type: {
                    name: "Uuid"
                }
            },
            company: {
                serializedName: "company",
                type: {
                    name: "Composite",
                    className: "CompanyTenderDbEntity"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            beginRequestDateTime: {
                serializedName: "beginRequestDateTime",
                type: {
                    name: "DateTime"
                }
            },
            endRequestDateTime: {
                serializedName: "endRequestDateTime",
                type: {
                    name: "DateTime"
                }
            },
            isRequestClosed: {
                serializedName: "isRequestClosed",
                type: {
                    name: "Boolean"
                }
            },
            registryRequests: {
                serializedName: "registryRequests",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RegistryRequestTenderDbEntity"
                        }
                    }
                }
            }
        }
    }
};
export const RegistryRequestStatusTenderDbEntity = {
    type: {
        name: "Composite",
        className: "RegistryRequestStatusTenderDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryRequests: {
                serializedName: "registryRequests",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RegistryRequestTenderDbEntity"
                        }
                    }
                }
            }
        }
    }
};
export const RequestAccreditationDocumentTenderDbEntity = {
    type: {
        name: "Composite",
        className: "RequestAccreditationDocumentTenderDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            requestId: {
                serializedName: "requestId",
                type: {
                    name: "Uuid"
                }
            },
            registryRequestTenderDbEntity: {
                serializedName: "registryRequestTenderDbEntity",
                type: {
                    name: "Composite",
                    className: "RegistryRequestTenderDbEntity"
                }
            },
            fileId: {
                serializedName: "fileId",
                type: {
                    name: "Uuid"
                }
            },
            fileName: {
                serializedName: "fileName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            documentType: {
                serializedName: "documentType",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const RegistryRequestStatusLogTenderDbEntity = {
    type: {
        name: "Composite",
        className: "RegistryRequestStatusLogTenderDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            requestId: {
                serializedName: "requestId",
                type: {
                    name: "Uuid"
                }
            },
            request: {
                serializedName: "request",
                type: {
                    name: "Composite",
                    className: "RegistryRequestTenderDbEntity"
                }
            },
            userId: {
                serializedName: "userId",
                type: {
                    name: "Uuid"
                }
            },
            createDate: {
                serializedName: "createDate",
                type: {
                    name: "DateTime"
                }
            },
            previousStatusId: {
                serializedName: "previousStatusId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            previousStatus: {
                serializedName: "previousStatus",
                type: {
                    name: "Composite",
                    className: "RegistryRequestStatusTenderDbEntity"
                }
            },
            newStatusId: {
                serializedName: "newStatusId",
                type: {
                    name: "Number"
                }
            },
            newStatus: {
                serializedName: "newStatus",
                type: {
                    name: "Composite",
                    className: "RegistryRequestStatusTenderDbEntity"
                }
            }
        }
    }
};
export const AccreditationResultDto = {
    type: {
        name: "Composite",
        className: "AccreditationResultDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            notes: {
                serializedName: "notes",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            userId: {
                serializedName: "userId",
                type: {
                    name: "Uuid"
                }
            },
            registryId: {
                serializedName: "registryId",
                type: {
                    name: "Uuid"
                }
            },
            sourceCompanyId: {
                serializedName: "sourceCompanyId",
                type: {
                    name: "Uuid"
                }
            },
            sourceCompany: {
                serializedName: "sourceCompany",
                type: {
                    name: "Composite",
                    className: "CompanyDto"
                }
            },
            taxSystem: {
                serializedName: "taxSystem",
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            registryRequestStatusId: {
                serializedName: "registryRequestStatusId",
                type: {
                    name: "Number"
                }
            },
            registryRequestStatusName: {
                serializedName: "registryRequestStatusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            resultNotes: {
                serializedName: "resultNotes",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const CompanyDto = {
    type: {
        name: "Composite",
        className: "CompanyDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fullOfficialName: {
                serializedName: "fullOfficialName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            inn: {
                serializedName: "inn",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            officialAddress: {
                serializedName: "officialAddress",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            phone: {
                serializedName: "phone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            ogrn: {
                serializedName: "ogrn",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            kpp: {
                serializedName: "kpp",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const AdminAccreditationDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "AdminAccreditationDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "AdminAccreditationDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const AccreditationCreateRequest = {
    type: {
        name: "Composite",
        className: "AccreditationCreateRequest",
        modelProperties: {
            name: {
                constraints: {
                    MaxLength: 128
                },
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            companyId: {
                serializedName: "companyId",
                type: {
                    name: "Uuid"
                }
            },
            beginRequestDateTime: {
                serializedName: "beginRequestDateTime",
                type: {
                    name: "DateTime"
                }
            },
            endRequestDateTime: {
                serializedName: "endRequestDateTime",
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const AccreditationFullDtoApiResponse = {
    type: {
        name: "Composite",
        className: "AccreditationFullDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "AccreditationFullDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const AccreditationFullDto = {
    type: {
        name: "Composite",
        className: "AccreditationFullDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isCompleted: {
                serializedName: "isCompleted",
                type: {
                    name: "Boolean"
                }
            },
            companyName: {
                serializedName: "companyName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            beginRequestDateTime: {
                serializedName: "beginRequestDateTime",
                type: {
                    name: "DateTime"
                }
            },
            endRequestDateTime: {
                serializedName: "endRequestDateTime",
                type: {
                    name: "DateTime"
                }
            },
            requests: {
                serializedName: "requests",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "AccreditationRequestDto"
                        }
                    }
                }
            },
            isRequestClosed: {
                serializedName: "isRequestClosed",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const AccreditationRequestDto = {
    type: {
        name: "Composite",
        className: "AccreditationRequestDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            companyId: {
                serializedName: "companyId",
                type: {
                    name: "Uuid"
                }
            },
            companyName: {
                serializedName: "companyName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            accreditationRequestStatusId: {
                serializedName: "accreditationRequestStatusId",
                type: {
                    name: "Number"
                }
            },
            accreditationRequestStatusName: {
                serializedName: "accreditationRequestStatusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            documents: {
                serializedName: "documents",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "AccreditationDocumentDto"
                        }
                    }
                }
            }
        }
    }
};
export const AccreditationDocumentDto = {
    type: {
        name: "Composite",
        className: "AccreditationDocumentDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            fileName: {
                serializedName: "fileName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            requestId: {
                serializedName: "requestId",
                type: {
                    name: "Uuid"
                }
            },
            documentType: {
                serializedName: "documentType",
                type: {
                    name: "String"
                }
            },
            documentTypeName: {
                serializedName: "documentTypeName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const CompanyDtoApiResponse = {
    type: {
        name: "Composite",
        className: "CompanyDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "CompanyDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CompanyDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "CompanyDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CompanyDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CompanyCreateRequest = {
    type: {
        name: "Composite",
        className: "CompanyCreateRequest",
        modelProperties: {
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fullOfficialName: {
                serializedName: "fullOfficialName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            inn: {
                serializedName: "inn",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            officialAddress: {
                serializedName: "officialAddress",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            phone: {
                serializedName: "phone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isAllowedCreateTender: {
                serializedName: "isAllowedCreateTender",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const CompanyUpdateRequest = {
    type: {
        name: "Composite",
        className: "CompanyUpdateRequest",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fullOfficialName: {
                serializedName: "fullOfficialName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            inn: {
                serializedName: "inn",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            officialAddress: {
                serializedName: "officialAddress",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            phone: {
                serializedName: "phone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            ogrn: {
                serializedName: "ogrn",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            kpp: {
                serializedName: "kpp",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isAllowedCreateTender: {
                serializedName: "isAllowedCreateTender",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const LabelDtoIListApiResponse = {
    type: {
        name: "Composite",
        className: "LabelDtoIListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "LabelDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const LabelDto = {
    type: {
        name: "Composite",
        className: "LabelDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            colorHexCode: {
                serializedName: "colorHexCode",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const RegistryDetailedDtoApiResponse = {
    type: {
        name: "Composite",
        className: "RegistryDetailedDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "RegistryDetailedDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const RegistryDetailedDto = {
    type: {
        name: "Composite",
        className: "RegistryDetailedDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            registry: {
                serializedName: "registry",
                type: {
                    name: "Composite",
                    className: "RegistryDto"
                }
            },
            registryRequests: {
                serializedName: "registryRequests",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RegistryRequestDto"
                        }
                    }
                }
            }
        }
    }
};
export const RegistryRequestDto = {
    type: {
        name: "Composite",
        className: "RegistryRequestDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            companyId: {
                serializedName: "companyId",
                type: {
                    name: "Uuid"
                }
            },
            companyName: {
                serializedName: "companyName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryRequestStatusId: {
                serializedName: "registryRequestStatusId",
                type: {
                    name: "Number"
                }
            },
            registryRequestStatusName: {
                serializedName: "registryRequestStatusName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const RegistryDtoIListApiResponse = {
    type: {
        name: "Composite",
        className: "RegistryDtoIListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RegistryDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ReplyDetailedDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ReplyDetailedDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ReplyDetailedDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ReplyDetailedDto = {
    type: {
        name: "Composite",
        className: "ReplyDetailedDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            request: {
                serializedName: "request",
                type: {
                    name: "Composite",
                    className: "RequestDetailedDto"
                }
            },
            reply: {
                serializedName: "reply",
                type: {
                    name: "Composite",
                    className: "ReplyDto"
                }
            }
        }
    }
};
export const RequestDetailedDto = {
    type: {
        name: "Composite",
        className: "RequestDetailedDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            request: {
                serializedName: "request",
                type: {
                    name: "Composite",
                    className: "RequestDto"
                }
            },
            replies: {
                serializedName: "replies",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ReplyRequestDetailedDto"
                        }
                    }
                }
            },
            requestAdditionalItems: {
                serializedName: "requestAdditionalItems",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestAdditionalItemDto"
                        }
                    }
                }
            }
        }
    }
};
export const RequestDto = {
    type: {
        name: "Composite",
        className: "RequestDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            companyName: {
                serializedName: "companyName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            labels: {
                serializedName: "labels",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestLabelDto"
                        }
                    }
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            noteDetails: {
                serializedName: "noteDetails",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            requestStatusId: {
                serializedName: "requestStatusId",
                type: {
                    name: "Number"
                }
            },
            destination: {
                serializedName: "destination",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            capacity: {
                serializedName: "capacity",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            requestStatusName: {
                serializedName: "requestStatusName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const RequestLabelDto = {
    type: {
        name: "Composite",
        className: "RequestLabelDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            colorHexCode: {
                serializedName: "colorHexCode",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ReplyRequestDetailedDto = {
    type: {
        name: "Composite",
        className: "ReplyRequestDetailedDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            companyId: {
                serializedName: "companyId",
                type: {
                    name: "Uuid"
                }
            },
            companyName: {
                serializedName: "companyName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            replyStatusId: {
                serializedName: "replyStatusId",
                type: {
                    name: "Number"
                }
            },
            replyStatusName: {
                serializedName: "replyStatusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const RequestAdditionalItemDto = {
    type: {
        name: "Composite",
        className: "RequestAdditionalItemDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            title: {
                serializedName: "title",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            text: {
                serializedName: "text",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            index: {
                serializedName: "index",
                type: {
                    name: "Number"
                }
            },
            dcouments: {
                serializedName: "dcouments",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestDocumentDto"
                        }
                    }
                }
            }
        }
    }
};
export const RequestDocumentDto = {
    type: {
        name: "Composite",
        className: "RequestDocumentDto",
        modelProperties: {
            id: {
                serializedName: "id",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fileName: {
                serializedName: "fileName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ReplyDto = {
    type: {
        name: "Composite",
        className: "ReplyDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            request: {
                serializedName: "request",
                type: {
                    name: "Composite",
                    className: "RequestDto"
                }
            },
            companyId: {
                serializedName: "companyId",
                type: {
                    name: "Uuid"
                }
            },
            companyName: {
                serializedName: "companyName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            labels: {
                serializedName: "labels",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestLabelDto"
                        }
                    }
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            replyStatusId: {
                serializedName: "replyStatusId",
                type: {
                    name: "Number"
                }
            },
            replyStatusName: {
                serializedName: "replyStatusName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ReplyDtoIListApiResponse = {
    type: {
        name: "Composite",
        className: "ReplyDtoIListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ReplyDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const RequestDetailedDtoApiResponse = {
    type: {
        name: "Composite",
        className: "RequestDetailedDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "RequestDetailedDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const RequestForTourOperatorDetailedDtoApiResponse = {
    type: {
        name: "Composite",
        className: "RequestForTourOperatorDetailedDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "RequestForTourOperatorDetailedDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const RequestForTourOperatorDetailedDto = {
    type: {
        name: "Composite",
        className: "RequestForTourOperatorDetailedDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            request: {
                serializedName: "request",
                type: {
                    name: "Composite",
                    className: "RequestDto"
                }
            },
            replies: {
                serializedName: "replies",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ReplyRequestDetailedDto"
                        }
                    }
                }
            },
            requestAdditionalItems: {
                serializedName: "requestAdditionalItems",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestAdditionalItemDto"
                        }
                    }
                }
            }
        }
    }
};
export const RequestDtoIListApiResponse = {
    type: {
        name: "Composite",
        className: "RequestDtoIListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const TenderCreateRequest = {
    type: {
        name: "Composite",
        className: "TenderCreateRequest",
        modelProperties: {
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            companyId: {
                serializedName: "companyId",
                type: {
                    name: "Uuid"
                }
            },
            requestDueDateTime: {
                serializedName: "requestDueDateTime",
                type: {
                    name: "DateTime"
                }
            },
            resultDueDateTime: {
                serializedName: "resultDueDateTime",
                type: {
                    name: "DateTime"
                }
            },
            destination: {
                serializedName: "destination",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            capacity: {
                serializedName: "capacity",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isPrivate: {
                serializedName: "isPrivate",
                type: {
                    name: "Boolean"
                }
            },
            invitedCompanies: {
                serializedName: "invitedCompanies",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Uuid"
                        }
                    }
                }
            },
            additionalItems: {
                serializedName: "additionalItems",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TenderRequestAdditionalItemCreateRequest"
                        }
                    }
                }
            }
        }
    }
};
export const TenderRequestAdditionalItemCreateRequest = {
    type: {
        name: "Composite",
        className: "TenderRequestAdditionalItemCreateRequest",
        modelProperties: {
            requestId: {
                serializedName: "requestId",
                type: {
                    name: "Uuid"
                }
            },
            title: {
                serializedName: "title",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            text: {
                serializedName: "text",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            index: {
                serializedName: "index",
                type: {
                    name: "Number"
                }
            },
            documents: {
                serializedName: "documents",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            }
        }
    }
};
export const RequestDtoApiResponse = {
    type: {
        name: "Composite",
        className: "RequestDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "RequestDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ReplyRequest = {
    type: {
        name: "Composite",
        className: "ReplyRequest",
        modelProperties: {
            companyId: {
                serializedName: "companyId",
                type: {
                    name: "Uuid"
                }
            },
            requestId: {
                serializedName: "requestId",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const ReplyDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ReplyDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ReplyDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const FileStreamResultApiResponse = {
    type: {
        name: "Composite",
        className: "FileStreamResultApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Stream"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const Paths1Ryzwi1ApiAccreditationsRequestAccreditationPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths1Ryzwi1ApiAccreditationsRequestAccreditationPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            registryId: {
                serializedName: "registryId",
                type: {
                    name: "Uuid"
                }
            },
            notes: {
                serializedName: "notes",
                type: {
                    name: "String"
                }
            },
            taxSystm: {
                serializedName: "taxSystm",
                type: {
                    name: "String"
                }
            },
            companyId: {
                serializedName: "companyId",
                type: {
                    name: "Uuid"
                }
            },
            accreditationClaim: {
                serializedName: "accreditationClaim",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            efptExcerpt: {
                serializedName: "efptExcerpt",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            taxExcerpt: {
                serializedName: "taxExcerpt",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            budgetExcerpt: {
                serializedName: "budgetExcerpt",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            consistencyReference: {
                serializedName: "consistencyReference",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            legalEntitiesExcerpt: {
                serializedName: "legalEntitiesExcerpt",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            individualsInformationReference: {
                serializedName: "individualsInformationReference",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            incomeStatement: {
                serializedName: "incomeStatement",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            cashFlowStatement: {
                serializedName: "cashFlowStatement",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            revenueExplain: {
                serializedName: "revenueExplain",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            cashFlowExplain: {
                serializedName: "cashFlowExplain",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            accountingReference: {
                serializedName: "accountingReference",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            confirmationLetter: {
                serializedName: "confirmationLetter",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            }
        }
    }
};
