"use strict";
module.exports = {
    footerText: 'АНО "Больше, Чем Путешествие" © 2023',
    baseUrl: {
        /*   auth: "http://SletatRu.MttAdmin.cyberiasoft.com:81",
    api: "http://SletatRu.MttAdmin.cyberiasoft.com:82",
    gql: "http://SletatRu.MttAdmin.cyberiasoft.com:82/graphql"
*/
        auth: "http://192.168.10.223:9001",
        api: "http://localhost:5010",
        gql: "http://localhost:5010/graphql"
    }
};
