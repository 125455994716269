import { SWITCH_LANGUAGE, ON_AUTH, SIGNOUT_USER_SUCCESS } from "../../constants/ActionTypes";
import { LAYOUT_TYPE, LAYOUT_TYPE_FULL, NAV_STYLE, NAV_STYLE_NO_HEADER_MINI_SIDEBAR, THEME_COLOR, THEME_TYPE, THEME_TYPE_LITE, UPDATE_RTL_STATUS } from "../../constants/ThemeSetting";
export const loadState = (name) => {
    try {
        const serializedState = localStorage.getItem(name);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    }
    catch (err) {
        return undefined;
    }
};
const initialSettings = {
    navStyle: NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    layoutType: LAYOUT_TYPE_FULL,
    themeType: THEME_TYPE_LITE,
    themeColor: '',
    auth: {},
    isDirectionRTL: false,
    locale: {
        languageId: 'russian',
        locale: 'ru',
        name: 'Русский',
        icon: 'ru'
    }
};
const authState = loadState('auth_data');
if (authState !== undefined) {
    initialSettings.auth = authState;
}
else {
    initialSettings.auth = {
        userName: '',
        name: '',
        email: '',
        auth_token: '',
        isAuth: false,
        roles: []
    };
}
const SettingsReducer = (state = initialSettings, action) => {
    switch (action.type) {
        case THEME_TYPE:
            return Object.assign(Object.assign({}, state), { themeType: action.themeType });
        case THEME_COLOR:
            return Object.assign(Object.assign({}, state), { themeColor: action.themeColor });
        case UPDATE_RTL_STATUS:
            return Object.assign(Object.assign({}, state), { isDirectionRTL: action.rtlStatus });
        case NAV_STYLE:
            return Object.assign(Object.assign({}, state), { navStyle: action.navStyle });
        case LAYOUT_TYPE:
            return Object.assign(Object.assign({}, state), { layoutType: action.layoutType });
        case SWITCH_LANGUAGE:
            return Object.assign(Object.assign({}, state), { locale: action.payload });
        case ON_AUTH:
            return Object.assign(Object.assign({}, state), { auth: action.payload });
        case SIGNOUT_USER_SUCCESS:
            return Object.assign(Object.assign({}, state), { auth: null });
        default:
            return state;
    }
};
export default SettingsReducer;
