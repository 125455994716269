import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from 'util/asyncComponent';
import { useSelector } from 'react-redux';
const RequireAuth = ({ children }) => {
    const { auth } = useSelector((e) => e.settings);
    if (auth === null || auth === undefined || auth.isAuth !== true) {
        return _jsx(Redirect, { to: '/user-auth/sign-in' });
    }
    return children;
};
const App = ({ match }) => (_jsxs(Switch, { children: [_jsx(Route, { path: `${match.url}user-auth`, component: asyncComponent(() => import('./userAuth')) }), _jsx("div", Object.assign({ className: "gx-main-content-wrapper" }, { children: _jsxs(RequireAuth, { children: [_jsx(Route, { exact: true, path: `${match.url}to/requests`, component: asyncComponent(() => import('./tourOperatorRequests')) }), _jsx(Route, { exact: true, path: `${match.url}admin/requests`, component: asyncComponent(() => import('./adminRequests')) }), _jsx(Route, { exact: true, path: `${match.url}replies`, component: asyncComponent(() => import('./replies')) }), _jsx(Route, { exact: true, path: `${match.url}to/accreditations`, component: asyncComponent(() => import('./tourOperatorAccreditations')) }), _jsx(Route, { exact: true, path: `${match.url}admin/accreditations`, component: asyncComponent(() => import('./adminAccreditations')) }), _jsx(Route, { exact: true, path: `${match.url}my-company`, component: asyncComponent(() => import('./myCompany')) }), _jsx(Route, { exact: true, path: `${match.url}to/companies`, component: asyncComponent(() => import('./tourOperatorCompanies')) })] }) }))] }));
export default App;
